import React, { useEffect, useState } from 'react';
import {
  Layout,
  theme,
  Grid,
  Button,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { LeaveCategory, LeaveProvider } from '../../features/leave/provider/GenericLeaveProvider';
import UserDropdown from './UserDropdown';

const { Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const MasterLayout: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { xs } = breakpoints;

  // Initialize collapsed state based on xs (only if it's not undefined)
  const [collapsed, setCollapsed] = useState(() => (xs === true));

  const {
    token: {
      colorBgContainer,
    },
  } = theme.useToken();

  useEffect(() => {
    // Only update collapsed state if xs is defined
    if (xs !== undefined) {
      setCollapsed(xs);
    }
  }, [xs]);


  return (
    <LeaveProvider category={LeaveCategory.MyLeaves}>
      <Layout style={{ minHeight: '100vh' }}>

        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          {xs && <div className='px-2 pt-2 flex flex-row justify-between'>
            <Button onClick={() => setCollapsed(false)} icon={<MenuOutlined />}></Button>
            <UserDropdown />
          </div>}
          <div
            className="flex flex-col "
            style={{
              marginLeft: collapsed ? (xs ? 0 : 100) : (xs ? 0 : 250),
              transition: 'margin 0.3s ease',
              flex: '1 0 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Content
              style={{
                overflow: 'initial',
                paddingLeft: xs ? 5 : 20,
                paddingRight: xs ? 5 : 20,
              }}
            >
              <Outlet />
            </Content>
          </div>
          <Footer
            style={{
              textAlign: 'start',
              background: colorBgContainer,
              marginLeft: collapsed ? (xs ? 0 : 100) : (xs ? 0 : 225),
              transition: 'margin 0.3s ease',
            }}
          >
            <div className="text-gray-900 order-2 order-md-1">
              <span className="text-muted fw-semibold me-1">
                Copyright 2019-{new Date().getFullYear().toString()} © <a
                  href="https://konnecta.io/"
                  target="_blank"
                  className="text-gray-800 text-hover-primary"
                >
                  Konnecta Systems
                </a>. All
                Rights Reserved.
              </span>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </LeaveProvider>
  );
};

export { MasterLayout };
