import {
    FC,
    useEffect,
    createContext,
    useContext,
} from 'react';

import { WithChildren } from '../../@types/Utils';

type ImportMetaEnv = {
    [key: string]: string | boolean | undefined;
};
type FeatureContextProps = {
    showFeature: (val: string) => boolean;
};

const initAuthContextPropsState = {
    showFeature: () => false,
};

const FeatureContext = createContext<FeatureContextProps>(initAuthContextPropsState);
FeatureContext.displayName = 'Feature Controller';

const useFeature = () => {
    return useContext(FeatureContext);
};

const FeatureProvider: FC<WithChildren> = ({ children }) => {
    const myObject: ImportMetaEnv = import.meta.env;

    function getValueByKey<T>(obj: { [key: string]: T }, key: string): T | undefined {
        console.log("'VITE_APP_' + key", + key)
        return obj['VITE_APP_' + key];
    }
    const showFeature = (key: string) => {
        const val = getValueByKey(myObject, key);
        if (val === 'true') {
            return true;
        }
        return false;
    }

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {

    }, []);
    return (
        <FeatureContext.Provider
            value={{
                showFeature
            }}
        >
            {children}
        </FeatureContext.Provider>
    );
};

//changes here so refresh doesnt logout.


export { FeatureProvider, useFeature };
