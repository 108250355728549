import { Checkbox, Drawer, Input, Switch, TableColumnType, Typography } from 'antd';
import { ColumnTitle } from 'antd/es/table/interface';
import React, { Key, useEffect, useState } from 'react'
import { ExtendedTableColumnType } from '../../features/timesheet/components/TimesheetTable';
import { ExtendedColumnType } from './TableFilters';

type Props<T> = {
    openCustomisation: boolean;
    setOpenCustomisation: (d: boolean) => void;
    columns: ExtendedTableColumnType<T>[];
    localStorageKey: string,
    setNewColumns: React.Dispatch<React.SetStateAction<TableColumnType<T>[]>>;

}

const TableCustomisation = <T,>({ setOpenCustomisation, openCustomisation, columns, localStorageKey, setNewColumns }: Props<T>) => {
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [options, setOptions] = useState<{ label: ColumnTitle<T>, value: Key | undefined }[]>(columns.map(({ key, title }) => ({
        label: title,
        value: key,
    })));
    useEffect(() => {
        const storedData = window.localStorage.getItem(localStorageKey);
        if (storedData) {
            const data = JSON.parse(storedData) as ExtendedTableColumnType<T>[];
            if (data)
                setCheckedList(data.filter((t) => !t.hidden).map((t) => t.key as string))
            if (data.filter((d) => !d.hidden).length === 0) {
                setCheckedList(data.filter((t) => t.default).map((t) => t.key as string))
            }
        } else {
            setCheckedList(
                columns
                    .filter((t) => t.default === true)  // Only filter where t.default is explicitly true
                    .map((t) => t.key as string)
            );
        }

    }, []);
    useEffect(() => {
        const updatedColumns = columns.map(item => ({
            ...item,
            hidden: item.default ? false : !checkedList.includes(item.key as string),
        }));

        setNewColumns(prevColumns => {
            if (JSON.stringify(prevColumns) !== JSON.stringify(updatedColumns)) {
                return updatedColumns;
            }

            return prevColumns;
        });

    }, [columns, checkedList]);
    const handleSearchOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value.toLowerCase();
        if (searchText) {
            setOptions(options.filter((opt) => {
                if (opt.label && opt.value) {
                    const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                    const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                    const lowercasedSearchText = searchText.toLowerCase();

                    return valueString.toLowerCase().includes(lowercasedSearchText) || labelString.toLowerCase().includes(lowercasedSearchText);
                }
            }
            ));
        } else {
            setOptions(columns.map(({ key, title }) => ({
                label: title,
                value: key,
            })))
        }

    };
    return (
        <Drawer
            width={300}
            title="Fields"
            onClose={() => setOpenCustomisation(false)}
            open={openCustomisation}
        >
            <div className='flex gap-4 flex-col'>
                <div>
                    <Input placeholder='Search...' onChange={(text) => handleSearchOptions(text)} allowClear />
                </div>
                <Checkbox.Group
                    value={checkedList}
                    onChange={(value) => {
                        setCheckedList(value as string[]);
                    }}
                    key={'12'}
                    className='flex justify-center  flex-col gap-4'
                >
                    <div className='flex flex-row justify-between'>
                        <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>Shown</Typography>
                        {checkedList.length > 0 && (
                            <Typography
                                className='cursor-pointer'
                                style={{ fontSize: 12 }}
                                onClick={() => setCheckedList(columns.filter((opt) => opt.default).map((t) => t.key as string))}
                            >
                                Hide all
                            </Typography>
                        )}
                    </div>
                    {options.map((opt) => {
                        const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                        const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                        if (checkedList.includes(valueString))
                            return (
                                <div className='flex flex-row justify-between' key={opt.value}>
                                    <div>
                                        <Typography>{labelString}</Typography>
                                    </div>
                                    <Switch
                                        style={{ background: '#7f77f1' }}
                                        size={"small"}
                                        checked={checkedList.includes(valueString)}
                                        onChange={(value) => {
                                            if (value) {
                                                setCheckedList([...checkedList, valueString]);
                                            } else {
                                                setCheckedList(checkedList.filter((item) => item !== opt.value));
                                            }
                                        }}
                                    />
                                </div>
                            );
                    })}

                    <div className='flex justify-between'>
                        <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>Hidden</Typography>
                        {checkedList.length !== columns.length && (
                            <Typography
                                className='cursor-pointer'
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    const storedData = window.localStorage.getItem(localStorageKey);
                                    if (storedData) {
                                        const data = JSON.parse(storedData) as ExtendedColumnType<T>[];
                                        if (data) {
                                            setCheckedList(data.map((t) => t.key as string));
                                        }
                                    }
                                }}
                            >
                                Show all
                            </Typography>
                        )}
                    </div>
                    {options.map((opt) => {
                        const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                        const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                        // Convert searchText to lowercase
                        if (!checkedList.includes(valueString))
                            return (
                                <div className='flex flex-row justify-between' key={opt.value}>
                                    <Typography>{labelString}</Typography>
                                    <Switch
                                        size={"small"}
                                        checked={checkedList.includes(valueString)}
                                        onChange={(value) => {
                                            if (value) {
                                                setCheckedList([...checkedList, valueString]);
                                            } else {
                                                setCheckedList(checkedList.filter((item) => item !== opt.value));
                                            }
                                        }}
                                    />
                                </div>
                            );
                    })}
                </Checkbox.Group>

            </div>
        </Drawer>
    )
}

export default TableCustomisation