import {
    FC,
    ReactNode,
    ReactPortal,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { fetchBalance } from '../../../api-requests/Balance';
import { BalanceData } from '../../../@types/Balance';
import { useAuth } from '../../auth';
import BalanceContext from './BalanceContext';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';

type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};
export type FilterData = {
    id: number;
    label: string;
    value: boolean;
};
export const BalanceProvider: FC<TUserProviderProps> = ({ children }) => {
    const { id } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [balance, setBalance] = useState<BalanceData[]>([]);
    const { openNotification } = useNotifications();

    const getBalance = async (year: number) => {
        setLoading(true);
        try {
            const res = await fetchBalance({ personId: id, year: year });
            setBalance(res.Results);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching balance:', error);
            throw error;
        }
    };
    useEffect(() => {
        if (id) {
            getBalance(new Date().getFullYear())
        }
    }, [id]);
    const contextValue = useMemo(
        () => ({
            loading,
            balance,
            getBalance,
        }),
        [
            loading,
            balance,
        ],
    );
    return (
        <BalanceContext.Provider value={contextValue}>
            {children}
        </BalanceContext.Provider>
    );
};
