import { Button, Grid } from "antd";
import EmployeeSidebar from "./EmployeeSidebar";

import { useEffect, useState } from "react";
import useTableContext from "../../features/table/provider/TableContext";
import { fetchRelatedPeople } from "../../api-requests/Employees";
import { useTeam } from "../../features/humanResources/teams/provider/useTeam";
import { randomHexColor } from "../../utils/colourFunctions";
import AvatarGroupCell from "../cells/AvatarGroupCell";
import { UsergroupAddOutlined } from '@ant-design/icons';
import { EmployeeSimple } from "../../@types/Employee";
import { useNotifications } from "../../permissions/components/timesheet/Notification-service";
import { ValidationError } from "yup";

type AssigneesButtonProps = {
    title?: string
}
const { useBreakpoint } = Grid;

export const AssigneesButton = ({ title }: AssigneesButtonProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const { employeeIds, meMode } = useTableContext();
    const [employeeOptions, setEmployeeOptions] = useState<Array<EmployeeSimple>>(
        [],
    );
    const { md } = useBreakpoint();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { teams, getTeams } = useTeam();
    const { openNotification } = useNotifications();
    useEffect(() => {
        const fetchFilteredEmps = async () => {
            try {
                const fetchedEmployees = await fetchRelatedPeople({ searchTerm: searchTerm, includeInactive: false });
                const enrichedEmployees: EmployeeSimple[] =
                    fetchedEmployees.Results.map((result) => {
                        return {
                            ...result,
                            avatarColour: randomHexColor(),
                        };
                    });
                setEmployeeOptions(enrichedEmployees);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error instanceof ValidationError) {
                    openNotification('topRight', {
                        title: `Employees`,
                        description: 'Incomplete data for employees. ' + error.errors.join(','),
                        type: 'Warning',
                    });
                }
            }
        };
        const fetchTeamsFun = async () => {
            if (teams.length === 0) {
                try {
                    await getTeams({ searchTerm: searchTerm, includeInactive: false });
                } catch (error) {
                    console.error('Fetching teams', error)
                    if (error instanceof ValidationError) {
                        openNotification('topRight', {
                            title: `Teams`,
                            description: 'Incomplete data for teams. ' + error.errors.join(','),
                            type: 'Warning',
                        });
                    }
                }
            }

        }
        fetchFilteredEmps();
        fetchTeamsFun();
    }, [searchTerm]);
    return (
        <>
            <EmployeeSidebar title={title ? title : 'Assignees'} employeeOptions={employeeOptions} collapsed={collapsed} setCollapsed={setCollapsed} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Button
                size="small"
                className={`md:rounded-xl flex items-center transition-colors duration-200 
      }`}
                icon={<UsergroupAddOutlined />}
                onClick={() => setCollapsed(true)}
            >
                {employeeIds.length > 0 && !meMode ? (<div><AvatarGroupCell cell={employeeOptions.filter((emp) => employeeIds.includes(String(emp.Id)))} /></div>) : md && <span>{title ? title : 'Assignees'}</span>}
            </Button>
        </>)
}