import { Avatar, Tooltip } from 'antd'
import React from 'react'
import { hashCode, intToRGB } from '../generateColors';
import { isLightColour } from '../colourFunctions';

type Props = {
    fullName: string;
    doubleLetters: string[];
    size?: 'small' | 'large' | 'default'
}

const LoomAvatar = ({ fullName, doubleLetters, size = 'default' }: Props) => {
    return (
        <Tooltip title={fullName}>
            <Avatar size={size} style={{
                backgroundColor: fullName
                    ? '#' + intToRGB(hashCode(fullName))
                    : '',
                color: fullName &&
                    isLightColour('#' + intToRGB(hashCode(fullName)))
                    ? 'black'
                    : 'white',
            }}>
                {doubleLetters[0]}{doubleLetters[1]}
            </Avatar>
        </Tooltip>
    )
}

export default LoomAvatar