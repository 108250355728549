import { Route, Routes } from 'react-router-dom';
import { Login } from '../features/auth/components/Login/Login';
import { AuthLayout } from '../features/auth/AuthLayout';
import { LOGIN } from './Urls';

const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path={LOGIN} element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);
export default AuthRoutes;
