import { Team } from "../../@types/Team"

const initTeam: Team = {
    Id: 0,
    Name: '',
    Members: [],
    Leader: {
        Id: 0,
        FirstName: '',
        LastName: '',
        CallName: '',
        FullName: '',
    },
    Organisation: null,
}

export {
    initTeam
}