import { Outlet } from 'react-router-dom';
import { MasterInit } from './pages/layout/MasterInit';
import { AuthInit } from './features/auth';
import { NotificationProvider } from './permissions/components/timesheet/Notification-service';
import { TimesheetProvider } from './features/timesheet/provider/timesheetProvider';
import { Suspense } from 'react';
import { FeatureProvider } from './features/feature/FeatureProvider';
import { TeamProvider } from './features/humanResources/teams/provider/TeamProvider';
import { MeetingProvider } from './features/meeting/provider/meetingProvider';
import { TripProvider } from './features/trip/provider/tripProvider';
import { BalanceProvider } from './features/leave/provider/BalanceProvider';
import { LeaveCategory, LeaveProvider } from './features/leave/provider/GenericLeaveProvider';
import { Spin } from 'antd';
import { ProjectProvider } from './features/project/projects/provider/ProjectProvider';

const App = () => {
  return (
    <Suspense fallback={<Spin />}>
      <FeatureProvider>
        <NotificationProvider>
          <AuthInit>
            <ProjectProvider>
              <TeamProvider>
                <TimesheetProvider>
                  <LeaveProvider category={LeaveCategory.CalendarLeaves}>
                    <BalanceProvider>
                      <MeetingProvider>
                        <TripProvider>
                          <Outlet />
                          <MasterInit />
                        </TripProvider>
                      </MeetingProvider>
                    </BalanceProvider>
                  </LeaveProvider>
                </TimesheetProvider>
              </TeamProvider>
            </ProjectProvider>
          </AuthInit>
        </NotificationProvider>
      </FeatureProvider>
    </Suspense>
  );
};

export { App };
