import { BalanceData, LeavePerson, LeavePolicy } from "../../@types/Balance";
import { LeaveRequestPost } from "../../@types/Request";
import * as Yup from 'yup';

const createLeaveSchema = Yup.object().shape({
    Start: Yup.date(),
    End: Yup.date().default(new Date())
        .when("Start",
            (Start, yup) => Start && yup.min(Start, "End date cannot be before start date")),
    Description: Yup.string(),
    WorkableDays: Yup.number().min(1),
});

const emptyLeavePolicy: LeavePolicy = {
    Id: 0,
    MaxAllowableDays: 0,
    Carryover: false,
    Name: "",
    MaxCarryoverLimit: 0,
    NumberOfApprovalsNeeded: 0,
    RequiresApproval: false,
    RequiresEvidence: false,
    Country: {
        Id: 0,
        Name: "",
        ISOCode2: "",
        WorkingHoursOfDay: 0,
    },
    TypeOfLeave: {
        Id: 0,
        Name: "",
        Description: "",
    },
    Organisation: {
        Id: 0,
        ShortName: "",
        LegalName: "",
    },
};

const emptyLeavePerson: LeavePerson = {
    Id: 0,
    FirstName: "",
    LastName: "",
    FullName: "",
    CallName: "",
};

export const emptyBalanceData: BalanceData = {
    Id: 0,                          // Default for numbers
    Year: 0,
    LeaveQuota: 0,
    UsedDays: 0,
    RemainingDays: 0,
    PercentageConsumed: 0,
    Policy: emptyLeavePolicy,        // Initialize the LeavePolicy object
    Employee: {
        Id: 0,
        Active: false,
        Person: emptyLeavePerson,      // Initialize the LeavePerson object
        Organisation: {
            Id: 0,
            ShortName: "",
            LegalName: "",
        },
        leaveApprovers: [],            // Empty array for approvers
    },
};

const initLeaveRequest: LeaveRequestPost = {
    LeaveType: emptyBalanceData,
    Start: new Date(),
    End: new Date(),
    Description: '',
    Id: 0,
    leaveBalanceId: 0,
};

export {
    initLeaveRequest,
    createLeaveSchema
}