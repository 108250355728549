import { BalanceData } from '../../../../@types/Balance';
import CircularProgressWithIcon from '../../../../components/progress/CircularProgress';

type Props = {
  balanceData: BalanceData;
  title: string;
};

const SingleCardLeaveType = ({ balanceData, title }: Props) => {
  return (
    <div className="card mb-6 col-span-4  w-full">
      <div className="card-body items-stretch">
        <h3 className="card-title items-start flex flex-col mt-0">
          <span className="card-label fw-bold fs-3 mb-1">{title}</span>
        </h3>
        <div
          className="card-body flex flex-row justify-center items-center"
          style={{
            maxHeight: 130,
          }}
        >
          <div>
            <div>
              <CircularProgressWithIcon
                progress={balanceData.RemainingDays}
                divider={balanceData.LeaveQuota}
                size={250}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <p className="card-title h1">
              {balanceData.RemainingDays}/{balanceData.RemainingDays + balanceData.UsedDays}
            </p>
            <p>{balanceData.Policy.TypeOfLeave.Name}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCardLeaveType;
