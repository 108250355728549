import { useRef, useState } from 'react';
import {
    Modal,
    Button,
    Input,
    Upload,
    UploadFile,
} from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/airbnb.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TextArea from 'antd/es/input/TextArea';
import { useNotifications } from '../../permissions/components/timesheet/Notification-service';
import { createRequest } from '../../api-requests/support';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { createReportSchema } from '../../utils/FormValidation/ReportFormValidation';
type SetFieldValueFunction = (field: string, value: unknown) => void;

dayjs.extend(utc);
dayjs.extend(timezone);



type CalendarModalFormProps = {
    isOpen?: boolean;
    onClose: () => void;
};

const priority = [{ label: 'High Priority', color: '#fc8f8d' }, { label: 'Medium Priority', color: '#fab83e' }, { label: 'Low Priority', color: '#47cf90' }];

const ReportModal = ({
    isOpen,
    onClose,

}: CalendarModalFormProps) => {
    const { openNotification } = useNotifications();

    const [prio, setPrio] = useState('Medium Priority');
    const [fileList, setFileList] = useState<UploadFile<RcFile>[]>([]);
    const formRef = useRef<FormikProps<{ description: string, filename: string, fileBase64: string, title: string }>>(null);
    const submitStep = async (values: { description: string, filename: string, fileBase64: string, title: string }) => {
        try {
            await createRequest(values);
            openNotification('topRight', { title: 'Issue Report', description: 'Issue has been reported successfully', type: 'Success' });

            setPrio('Medium Priority');
        } catch (err) {

            console.error('Couldnt create issue report.');
            openNotification('topRight', { title: 'Issue Report', description: 'Creating issue report failed.', type: 'Danger' })

        }
        setFileList([]);
        onClose();
    };

    const getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result as string;
                const base64String = result.split(',')[1]; // Extract the Base64 string
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleUploadChange = async (info: UploadChangeParam, setFieldValue: SetFieldValueFunction) => {
        if (info.file) {
            const file = info.fileList[0].originFileObj;
            if (file) {
                try {
                    const base64 = await getBase64(file);
                    const filename = file.name;
                    setFileList(info.fileList);
                    setFieldValue('filename', filename);
                    setFieldValue('fileBase64', base64);

                } catch (error) {
                    console.error('Error reading file:', error);
                }
            }
        }
    };
    return (
        <div data-testvalue="calendarModal">
            <Formik
                innerRef={formRef}
                validationSchema={createReportSchema}
                initialValues={{ description: '', filename: '', fileBase64: '', title: '' }}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    await submitStep(values);
                    resetForm();
                }}
            >
                {({ values, setFieldValue, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            width={800}
                            open={isOpen}
                            onCancel={() => {
                                resetForm();
                                setPrio('Medium Priority')
                                setFileList([]);
                                onClose();
                            }}
                            title={'Report an issue'}
                            footer={[
                                <Button

                                    data-testvalue="submitLeave"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => {
                                        if (formRef.current) {
                                            formRef.current.handleSubmit()
                                        }
                                    }}
                                >
                                    <span className="indicator-label">Submit</span>
                                </Button>,
                            ]}
                        >
                            <div className={`flex flex-col justify-center gap-4`}>

                                <div data-testvalue="leaveTypeDropdown" className='flex gap-2 flex-col'>
                                    <div>
                                        <label className="form-label">Title</label>
                                    </div>
                                    <div className='flex flex-row gap-2 flex-wrap'
                                    >
                                        <Input
                                            name="Name"
                                            placeholder="Enter title"
                                            value={values.title}
                                            onChange={(e) => setFieldValue('title', e.target.value)}

                                        />
                                        <ErrorMessage name="title" className="text-red-500" >{msg => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>

                                    </div>
                                </div>
                                <div data-testvalue="leaveTypeDropdown" className='flex gap-2 flex-col'>
                                    <div>
                                        <label className="form-label">Please describe the issue you wish to report.</label>
                                    </div>
                                    <TextArea rows={4} onChange={(e) => setFieldValue('description', e.target.value)} value={values.description} />
                                    <ErrorMessage name="description" className="text-red-500" >{msg => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>

                                </div>
                                <div data-testvalue="leaveTypeDropdown" className='flex gap-2 flex-col'>
                                    <div>
                                        <label className="form-label">Issue Priority</label>
                                    </div>
                                    <div className='flex flex-row gap-2 flex-wrap'
                                    >
                                        {priority.map((err) => (
                                            <div key={err.label}>
                                                <Button style={{ border: '1px solvalue ' + err.color, backgroundColor: prio === err.label ? err.color : 'transparent' }} onClick={() => setPrio(err.label)}>{err.label}</Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div data-testvalue="leaveTypeDropdown" className='flex gap-2 flex-col'>
                                    <div>
                                        <label className="form-label">Attachment File</label>
                                    </div>                                    <div className='flex flex-row gap-2 flex-wrap'
                                    >
                                        <Upload
                                            multiple={false}
                                            onChange={(info) => handleUploadChange(info, setFieldValue)}
                                            beforeUpload={() => false}
                                            maxCount={1}
                                            fileList={fileList.length > 0 ? fileList : []}
                                        >
                                            <Button icon={<FileAddOutlined />}>Upload files</Button>
                                        </Upload>
                                    </div>
                                </div>

                            </div>
                        </Modal>
                    </form>
                )}
            </Formik>
        </div >
    );
};

export default ReportModal;
