import { ReactNode, createContext, useContext, useState } from 'react';
import { LeaveData, LeaveRequest } from '../../../mock/Leave';
import { approveSingleLeave, fetchApprovalList, fetchLeaveById, fetchLeaves, fetchMyLeaves, rejectSignleLeave, withdrawLeave } from '../../../api-requests/Leave';
import { LeaveOptions } from '../../../@types/FetchOptions';

export interface LeaveContextProps<T> {
    leaves: T[];
    fetchLeavesFunc: (params: LeaveOptions) => any;
    withdrawLeave: (id: number) => void;
    loadingFetch: boolean;
    getSingleLeave: (id: number) => Promise<LeaveData | LeaveRequest>;
    totalLeaves: number;
    approveLeave: (id: number, comments: string) => Promise<any>;
    rejectLeave: (id: number, comments: string) => Promise<any>;
}
const CalendarLeaveContext = createContext<
    LeaveContextProps<LeaveData> | undefined
>(undefined);
const ApprovalLeaveContext = createContext<
    LeaveContextProps<LeaveRequest> | undefined
>(undefined);
const MyLeaveContext = createContext<LeaveContextProps<LeaveData> | undefined>(
    undefined,
);
const HistoryLeaveContext = createContext<LeaveContextProps<LeaveData> | undefined>(undefined)
function useLeaveContext<T>(
    context: React.Context<LeaveContextProps<T> | undefined>,
): LeaveContextProps<T> {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error('useLeaveContext must be used within a LeaveProvider');
    }
    return ctx;
}
type LeaveType = LeaveData | LeaveRequest;
const createLeaveProvider = <T extends LeaveType>(
    context: React.Context<LeaveContextProps<T> | undefined>,
    removeLeaveApi: (id: number) => Promise<void>,
    fetchApi: (params: LeaveOptions) => any,
) => {
    return ({ children }: { children: ReactNode }) => {
        const [leaves, setLeaves] = useState<T[]>([]);
        const [loadingFetch, setLoadingFetch] = useState(false);
        const [totalLeaves, setTotalLeaves] = useState(0);
        const fetchLeavesFunc = async (params: LeaveOptions) => {
            setLoadingFetch(true);
            try {
                const myLeaves = await fetchApi({ ...params });
                setLeaves([...myLeaves.Results]);
                setTotalLeaves(myLeaves.TotalResults);
                return myLeaves.Results
            } catch (err) {
                console.warn('error fetching', err);
            } finally {
                setLoadingFetch(false);
            }
        }
        const withdrawLeave = async (id: number) => {
            await removeLeaveApi(id);
            setLeaves(leaves.map((leave) => {
                if (leave.Id === id) {
                    if ('Status' in leave) {
                        leave.Status = 3;
                        return leave
                    } else if ('FinalStatus' in leave) {
                        leave.FinalStatus = 3;
                        return leave
                    }
                }
                return leave;
            }));
        };
        const approveLeave = async (id: number, comments: string) => {
            try {
                await approveSingleLeave({ id: id, comments: comments });
                setLeaves(leaves.map((app) => {
                    if (app.Id === id && 'Status' in app) {
                        app.Status = 1;
                        return app;
                    } else {
                        return app;
                    }
                }));
            } catch (err) {
                console.error(err);
                throw err;
            }
        }
        const rejectLeave = async (id: number, comments: string) => {
            try {
                await rejectSignleLeave({ id: id, comments: comments });
                setLeaves(leaves.map((app) => {
                    if (app.Id === id && 'Status' in app) {
                        app.Status = 2;
                        return app;
                    } else {
                        return app;
                    }
                }));
            } catch (err) {
                console.error(err);
                throw err;
            }
        }
        const getSingleLeave = async (id: number) => {
            try {
                const leave = await fetchLeaveById(id);
                return leave;
            } catch (error) {
                throw error;
            }

        };
        return (
            <context.Provider value={{ leaves, fetchLeavesFunc, withdrawLeave, loadingFetch, getSingleLeave, totalLeaves, approveLeave, rejectLeave }}>
                {children}
            </context.Provider>
        );
    };
};

const CalendarLeaveProvider =
    createLeaveProvider<LeaveData>(CalendarLeaveContext, withdrawLeave, fetchLeaves);
const ApprovalLeaveProvider =
    createLeaveProvider<LeaveRequest>(ApprovalLeaveContext, withdrawLeave, fetchApprovalList);
const MyLeaveProvider = createLeaveProvider<LeaveData>(MyLeaveContext, withdrawLeave, fetchMyLeaves);
const HistoryLeaveProvider = createLeaveProvider<LeaveData>(HistoryLeaveContext, withdrawLeave, fetchLeaves)
enum LeaveCategory {
    CalendarLeaves = 'calendarLeaves',
    ApprovalLeaves = 'approvalLeaves',
    MyLeaves = 'myLeaves',
    HistoryLeaves = 'historyLeaves',
}
interface LeaveProviderProps {
    category: LeaveCategory;
    children: React.ReactNode;
}
const LeaveProvider: React.FC<LeaveProviderProps> = ({
    category,
    children,
}) => {
    switch (category) {
        case LeaveCategory.CalendarLeaves:
            return <CalendarLeaveProvider>{children}</CalendarLeaveProvider>;
        case LeaveCategory.ApprovalLeaves:
            return <ApprovalLeaveProvider>{children}</ApprovalLeaveProvider>;
        case LeaveCategory.MyLeaves:
            return <MyLeaveProvider>{children}</MyLeaveProvider>;
        case LeaveCategory.HistoryLeaves:
            return <HistoryLeaveProvider>{children}</HistoryLeaveProvider>;
        default:
            throw new Error('Invalid leave category');
    }
};

export {
    LeaveProvider,
    LeaveCategory,
    useLeaveContext,
    CalendarLeaveContext,
    ApprovalLeaveContext,
    MyLeaveContext,
    HistoryLeaveContext,
};
