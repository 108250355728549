

import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { Logout, useAuth } from '../features/auth';
import { App } from '../App';
import Callback from '../features/auth/components/Callback';
import AuthRoutes from './AuthRoutes';

const AppRoutes: FC = () => {
  const { auth } = useAuth();
  return (
    <Routes>
      <Route element={<App />}>
        {/* <Route path="error/*" element={<ErrorsRoutes />} /> */}
        <Route path="logout" element={<Logout />} />
        {auth ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="auth/callback/*" element={<Callback />} />
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
