import { theme } from 'antd';

type Props = {
    children: JSX.Element | JSX.Element[];
    padding?: number;
    bg?: string;
    p?: number
}

const PageWrapper = ({ children, padding = 30, bg }: Props) => {
    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    return (
        <div style={{ padding: 5 }}>
            <div
                style={{
                    background: bg ? bg : '',
                    minHeight: 280,
                    padding: padding,
                    borderRadius: borderRadiusLG,
                    position: 'relative',
                }}
                className={`bg-white shadow-md md:p-[${padding}px] py-[30px]`}
            >
                {children}
            </div>
        </div>

    )
}

export default PageWrapper