import React from 'react'
import UserDropdown from './UserDropdown';
import { Grid } from 'antd';

type Props = {
    children: React.ReactElement;
    title: string;
}
const { useBreakpoint } = Grid;

const Header = (props: Props) => {
    const { xs } = useBreakpoint();
    return (
        <div className='pb-12'>
            <div className='flex justify-between items-center mt-4 mb-4 ml-2'>
                <p className='text-pageTitle ' style={{ marginBottom: 0, marginTop: 0 }}>
                    {props.title}
                </p>
                {!xs && <UserDropdown />}
            </div>
            {props.children}
        </div>
    )
}

export default Header