import { createContext } from 'react';
import { FetchTimesheetOptions } from '../../../@types/FetchOptions';
import { Timesheet } from '../../../@types/Timesheet';

export interface ITimesheetContext {
  timesheets: Timesheet[];
  loading: boolean;
  timesheetsTotal: number;
  getTimesheets: (options: FetchTimesheetOptions) => void;
  getTimesheetsPerWP: (options: FetchTimesheetOptions) => void;
  timesheetsPerWP: any[];
  timesheetWPTotal: number;
}

const TimesheetContext = createContext<ITimesheetContext | undefined>(
  undefined,
);

TimesheetContext.displayName = 'Timesheet';

export default TimesheetContext;
