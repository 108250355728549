import React, { useMemo } from 'react';
import BalanceContext from './BalanceContext';

export const useBalance = () => {
    const context = React.useContext(BalanceContext);

    if (context === undefined) {
        throw new Error('useBalance must be used within a BalanceProvider');
    }
    // Memoize the context value to prevent unnecessary re-renders
    const memoizedContext = useMemo(() => context, [context]);
    return memoizedContext;
};
