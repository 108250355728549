import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { FetchTimesheetOptions } from '../@types/FetchOptions';
import { Timesheet, TimesheetPerWP, TimesheetPrint, TimesheetStatus } from '../@types/Timesheet';
import { CommonListResponse } from '../@types/Response';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const PUT_TIMESHEET = `${API_URL}/timesheets/timesheet`;
export const GET_TIMESHEETS = `${API_URL}/timesheets/list`;
export const APPROVE_TIMESHEET = `${API_URL}/timesheets/approve`;
export const REJECT_TIMESHEET = `${API_URL}/timesheets/reject`;
export const GET_TIMESHEETS_BY_ID = `${API_URL}/timesheets/get`;
export const GET_TIMESHEETS_PER_WP = `${API_URL}/timesheets/entries`;
export const createTimesheet = async (data: Timesheet) => {
  try {
    const response = await axios.post<''>(PUT_TIMESHEET, [data]);
    return response.data;
  } catch (error) {
    console.error('Error creating timesheet:', error);
    throw error;
  }
};

export const fetchTimesheetPerWP = async ({
  searchTerm = '',
  from = '',
  to = '',
  pageSize = 10,
  pageIndex = 0,
  status = '0,1,2,3',
  sorting = '',
  orgId = '0',
  employeeId = 0,
}: FetchTimesheetOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    from,
    to,
    status,
    sorting,
    orgId: String(orgId),
    employeeId: employeeId.toString(),
  });

  try {
    const response = await axios.get<CommonListResponse<TimesheetPerWP>>(
      `${GET_TIMESHEETS_PER_WP}?${params.toString()}`,
    );
    return response?.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error fetching timesheet:', error);
    throw error;
  }
};

export const fetchTimesheet = async ({
  searchTerm = '',
  from = '',
  to = '',
  pageSize = 10,
  pageIndex = 0,
  status = '0,1,2,3',
  sorting = 'Month:desc',
  orgId = '0',
  employeeId = 0,
}: FetchTimesheetOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    from,
    to,
    status,
    sorting,
    orgId: String(orgId),
    employeeId: employeeId.toString(),
  });

  try {
    const response = await axios.get<CommonListResponse<Timesheet>>(
      `${GET_TIMESHEETS}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error fetching timesheet:', error);
    throw error;
  }
};

export const approveTimesheet = async (id: number) => {
  return await axios.post(APPROVE_TIMESHEET, JSON.stringify(id));
};

export const rejectTimesheet = async (id: number) => {
  return await axios.post(REJECT_TIMESHEET, JSON.stringify(id));
};

export const getTimesheetById = async (id: number) => {
  try {
    const response = await axios.get<Timesheet>(
      `${GET_TIMESHEETS_BY_ID}?id=${id}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet by id:', error);
    throw error;
  }
};

export const GET_TIMESHEET_PDF = `${API_URL}/timesheets/print`;

export const fetchTimesheetPrint = async ({
  projectId,
  employeeId,
  monthFrom,
  monthTo,
  timesheetId,
}: TimesheetPrint) => {
  try {
    const response = await axios.post<Blob>(
      GET_TIMESHEET_PDF,
      {
        projectId: projectId ?? '',
        employeeId: employeeId ?? '',
        monthFrom: monthFrom instanceof Date ? monthFrom.toISOString() : '',
        monthTo: monthTo instanceof Date ? monthTo.toISOString() : '',
        timesheetId: timesheetId ?? '',
      },
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(response.data); // Create a URL object for the Blob data
    const anchor = document.createElement('a'); // Create an anchor element to trigger the download
    anchor.href = url;
    anchor.download = 'timesheet.pdf'; // Set the filename for the downloaded file
    anchor.click(); // Simulate a click on the anchor element to initiate the download
    window.URL.revokeObjectURL(url); // Clean up by revoking the URL object after the download is complete

    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet print:', error);
    throw error;
  }
};

export const TimeSheetTypedStatus = {
  0: 'Draft',
  1: 'Submitted',
  2: 'Approved',
  3: 'Rejected',
  7: 'Rejected',
  6: 'Cancelled',
} as const;

export function isTimesheetStatus(status: unknown): status is TimesheetStatus {
  return (status as TimesheetStatus) in TimeSheetTypedStatus;
}
