import { Input, Tooltip } from 'antd';
import React, { ChangeEvent, FocusEvent } from 'react';
import './css/LoomInput.css';
import { ErrorMessage } from 'formik';
import { InfoCircleOutlined } from '@ant-design/icons'
type Props = {
    prefix?: React.ReactElement;
    value: any;
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean | undefined;
    defaultValue?: string | number | readonly string[] | undefined;
    label?: string | React.ReactElement;
    required?: boolean;
    name?: string,
    color?: string,
    type?: string,
    step?: number,
    min?: number,
    max?: number,
    onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
    suffix?: React.ReactNode;
    info?: string;
};

const LoomInput = ({
    prefix,
    value,
    placeholder,
    onChange,
    disabled = false,
    label,
    required = false,
    name = '',
    color,
    type,
    step,
    min,
    defaultValue,
    onBlur,
    suffix,
    info,
}: Props) => {
    return (
        <div className="flex gap-2 flex-col w-full">
            {label && (
                <div className='flex items-center gap-2'>
                    <label className={`form-label ${required ? 'required' : ''}`}>
                        {label}
                    </label>
                    {
                        info && <span>
                            <Tooltip title={info}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </span>
                    }

                </div>
            )}
            <Input
                type={type}
                step={step}
                placeholder={placeholder}
                prefix={prefix}
                value={value}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e)}
                disabled={disabled}
                className={`h-input`}
                min={min}
                suffix={suffix}
                onBlur={onBlur}
                style={{ color: color ? color : disabled ? 'black' : '' }}
            />
            {name.length > 0 && <ErrorMessage name={name} className="text-red-500">
                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>}

        </div>
    );
};

export default LoomInput;
