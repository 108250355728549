import { createContext } from 'react';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { Meeting } from '../../../@types/Meeting';
import { Trip } from '../../../@types/Trip';


export interface IMeetingContext {
    meetings: Meeting[];
    getMeeting: (params: LeaveOptions) => Promise<Meeting[] | undefined>;
    meetingsTotal: number;
    loading: boolean;
    getMeetingCalendar: (params: LeaveOptions | any) => Promise<Meeting[] | undefined>
    getTrips: (params: LeaveOptions) => void;
    trips: Trip[];
    tripsTotal: number;
    getMeetingById: (id: number) => Promise<Meeting | undefined>;
    getPersonalMeetings: (params: LeaveOptions) => Promise<Meeting[] | undefined>;
}

const MeetingContext = createContext<IMeetingContext | undefined>(
    undefined,
);

MeetingContext.displayName = 'Meeting';

export default MeetingContext;
