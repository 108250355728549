import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';
import MeetingContext from './meetingContext';
import { useAuth } from '../../auth';
import { fetchMeetingById, fetchMeetings, fetchMyMeetings } from '../../../api-requests/Meeting';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { Meeting } from '../../../@types/Meeting';
import { Trip } from '../../../@types/Trip';
import { fetchTrips } from '../../../api-requests/Trip';

type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};

export const MeetingProvider: FC<TUserProviderProps> = ({ children }) => {
    const { id } = useAuth();
    const [meetings, setMeetings] = useState<Meeting[]>([]);
    const [trips, setTrips] = useState<Trip[]>([]);
    const [tripsTotal, setTripsTotal] = useState(0);
    const [meetingsTotal, setMeetingsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const getMeeting = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await fetchMeetings({ ...params });
            setMeetings(meetingResponse.Results);
            setMeetingsTotal(meetingResponse.TotalResults)
            return meetingResponse.Results;
        } catch (error) {
            console.error('Error fetching timesheets:', error);
        } finally {
            setLoading(false);
        }
    };
    const getTrips = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const tripResponse = await fetchTrips({ ...params });
            setTrips(tripResponse.Results);
            setTripsTotal(tripResponse.TotalResults)
        } catch (error) {
            console.error('Error fetching trip list:', error);
        } finally {
            setLoading(false);
        }
    }
    const getMeetingCalendar = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await fetchMeetings({ ...params, pageSize: 100 });
            setMeetings(meetingResponse.Results);
            setMeetingsTotal(meetingResponse.TotalResults)
            return meetingResponse.Results;
        } catch (error) {
            console.error('Error fetching timesheets:', error);
        } finally {
            setLoading(false);
        }
    };
    const getPersonalMeetings = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await fetchMyMeetings({ ...params, pageSize: 50 });
            setMeetings(meetingResponse.Results); // Update the state with filtered meetings
            setMeetingsTotal(meetingResponse.TotalResults);
            return meetingResponse.Results; // Return the filtered meetings directly
        } catch (error) {
            console.error('Error fetching timesheets:', error);
        } finally {
            setLoading(false);
        }
    };

    const getMeetingById = async (id: number) => {
        setLoading(true);
        try {
            const meetingResponse = await fetchMeetingById(id);
            return meetingResponse;
        } catch (error) {
            console.error('error getting meeting by id', error)
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const contextValue = useMemo(
        () => ({
            meetings,
            getMeeting,
            meetingsTotal,
            loading,
            getMeetingCalendar,
            getTrips,
            getMeetingById,
            trips,
            tripsTotal,
            getPersonalMeetings,
        }),
        [meetings, meetingsTotal, loading, trips, tripsTotal],);
    return (
        <MeetingContext.Provider value={contextValue} >
            {children}
        </MeetingContext.Provider>
    );
};
