import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../pages/layout/MasterLayout';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import ErrorsRoutes from './ErrorsRoutes';
import { WithChildren } from '../@types/Utils';
import { useFeature } from '../features/feature/FeatureProvider';
import HumanResourcesRoutes from './HumanResourcesRoutes';
import { RunTimeError } from '../features/errors/components/RunTimeError';
import { useAuth } from '../features/auth';

const PrivateRoutes = () => {
  const { showFeature } = useFeature();
  const TimesheetRoutes = lazy(() => import('./TimesheetRoutes'));
  const LeaveRoutes = lazy(() => import('./LeaveRoutes'));
  const ProjectRoutes = lazy(() => import('./ProjectRoutes'));
  const MeetingRoutes = lazy(() => import('./MeetingRoutes'));
  const TripRoutes = lazy(() => import('./TripRoutes'));
  const { permissions } = useAuth(); // Get user permissions from context

  // Filter routes based on user permissions
  return (
    <Routes>
      <Route element={<MasterLayout />} errorElement={<RunTimeError />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        {showFeature('TIMESHEET') && <Route
          path="apps/timesheet/*"
          element={
            <SuspensedView>
              <TimesheetRoutes />
            </SuspensedView>
          }
        />}

        {showFeature('LEAVES') && <Route
          path="apps/leave/*"
          element={
            <SuspensedView>
              <LeaveRoutes />
            </SuspensedView>
          }
        />}
        {showFeature('HUMANRESOURCES') && <Route
          path="apps/human/*"
          element={
            <SuspensedView>
              <HumanResourcesRoutes />
            </SuspensedView>
          }
        />}
        {showFeature('MEETING') && <Route
          path="apps/projects/*"
          element={
            <SuspensedView>
              <ProjectRoutes />
            </SuspensedView>
          }
        />}
        {showFeature('MEETING') && <Route
          path="apps/projects/meetings/*"
          element={
            <SuspensedView>
              <MeetingRoutes />
            </SuspensedView>
          }
        />}
        {showFeature('MEETING') && <Route
          path="apps/projects/meetings/trip/*"
          element={
            <SuspensedView>
              <TripRoutes />
            </SuspensedView>
          }
        />}
        {/* Page Not Found */}
        <Route path="/*" element={<ErrorsRoutes />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {

  return <Suspense>{children}</Suspense>;
};

export default PrivateRoutes;
