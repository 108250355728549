import { Button, Grid } from "antd";
import useTableContext from "../../features/table/provider/TableContext";
import { UserOutlined } from '@ant-design/icons';


const { useBreakpoint } = Grid;

export const MeModeButton = () => {
    const { meMode, handleMeMode } = useTableContext();
    const { md } = useBreakpoint();

    return (
        <Button
            onClick={() => handleMeMode(!meMode)}
            size="small"
            className={`md:rounded-xl flex items-center transition-colors duration-200 ${meMode ? 'bg-primary text-white hover:bg-primary  ' : 'bg-white border text-gray-700 hover:bg-gray-100'
                }`}
            icon={<UserOutlined />}
        >
            {md && <span>Me mode</span>}
        </Button>
    );
};