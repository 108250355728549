import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';
import TeamContext from './TeamContext';
import { createTeamApi, deleteTeamId, fetchTeams } from '../../../../api-requests/Employees';
import { FetchEmployeesOptions } from '../../../../@types/Employee';
import { Team } from '../../../../@types/Team';

type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};

export const TeamProvider: FC<TUserProviderProps> = ({ children }) => {
    const [teams, setTeams] = useState<Team[]>([]);
    const [teamsTotal, setTeamsTotal] = useState(0)
    const [loading, setLoading] = useState(false);
    const getTeams = async (params: FetchEmployeesOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await fetchTeams({ ...params });
            setTeams(meetingResponse.Results);
            setTeamsTotal(meetingResponse.TotalResults)
            return teams;
        } catch (error) {
            console.error('Error fetching timesheets:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };
    const createTeams = async (values: Team) => {
        setLoading(true);
        try {
            await createTeamApi({ ...values });
        } catch (error) {
            console.error('Error creating teams:', error);
            throw error
        } finally {
            setLoading(false);
        }
    }
    const deleteTeam = async (id: number) => {
        setLoading(true);

        try {
            await deleteTeamId(id);
            setTeams(teams.filter((team) => team.Id !== id));
        } catch (err) {
            console.error('Delete team error:', err);
            throw err
        } finally {
            setLoading(false);

        }
    }
    const contextValue = useMemo(
        () => ({
            teams,
            getTeams,
            loading, teamsTotal, createTeams, deleteTeam
        }),
        [teams, loading],
    );
    return (
        <TeamContext.Provider value={contextValue} >
            {children}
        </TeamContext.Provider>
    );
};
