import { Dropdown, Menu, MenuProps, Table } from 'antd'
import React, { useState } from 'react'
import { ExtendedTableColumnType } from '../../timesheet/components/TimesheetTable';
import useTableContext from '../provider/TableContext';

type Props<T> = {
    columns: ExtendedTableColumnType<T>[];
    data: T[];
    totalData: number;
    loadingDataFetch: boolean;
    actions?: (record: T) => any;
}

const LoomTable = <T,>({ columns, data, totalData, loadingDataFetch, actions = () => [] }: Props<T>) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [currentRow, setCurrentRow] = useState<T | null>(null);

    const {
        pageSize,
        currentPage,
        handleTableChange,
    } = useTableContext();

    const handleRowRightClick = (record: T, event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault(); // Prevent the default right-click context menu
        setCurrentRow(record); // Set the current row data
        setDropdownPosition({ x: event.clientX, y: event.clientY }); // Set position for dropdown
        setDropdownVisible(true); // Show the dropdown
    };
    const getMenuItems = () => {
        if (currentRow && actions) {
            const menuItems: MenuProps['items'] = actions(currentRow);
            return (
                <Menu>
                    {menuItems && menuItems.map((item: any) => (
                        item && (
                            <Menu.Item key={item.key} onClick={item.onClick} disabled={item.disabled}>
                                {item.icon} {item.label}
                            </Menu.Item>
                        )
                    ))}
                </Menu>
            );
        }
        return null; // Return null if no actions or currentRow is null
    };
    return (
        <>
            <Table<T>
                columns={columns}
                dataSource={data}
                size={'middle'}
                scroll={{ x: columns.filter((cl) => !cl.hidden).length > 5 ? 800 : 1600 }}
                pagination={{
                    size: 'default',
                    position: ['bottomCenter'],
                    current: currentPage,
                    pageSize: pageSize?.value,
                    total: totalData,
                    pageSizeOptions: ['10', '25', '50'],
                }}
                onRow={(record) => ({
                    onContextMenu: (event) => handleRowRightClick(record, event), // Right-click row to show menu
                })}
                sortDirections={['ascend', 'descend']}
                onChange={handleTableChange}
                loading={loadingDataFetch}
                rowKey={'Id'}
                className="custom-antd-header-bg"
            />
            {
                dropdownVisible && (
                    <Dropdown
                        overlay={getMenuItems() as any} // Call the function to get the Menu component
                        trigger={['contextMenu']}
                        open={dropdownVisible}
                        onOpenChange={(visible) => setDropdownVisible(visible)}
                        overlayStyle={{
                            position: 'fixed',
                            left: dropdownPosition.x,
                            top: dropdownPosition.y,
                        }}
                    >
                        <div style={{ position: 'absolute', left: dropdownPosition.x, top: dropdownPosition.y, width: 1, height: 1 }} />
                    </Dropdown>
                )}
        </>
    )
}

export default LoomTable