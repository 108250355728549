import React, { ReactElement } from "react";
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tooltip } from "antd";

function ActionCell<T extends { Id: number | null }>(props: {
    data: T;
    items: MenuProps['items']
}): ReactElement {
    const { items } = props;
    return (
        <div className="flex gap-2">
            <div
                className="flex items-center justify-center gap-1"
                style={{ width: "100%" }}
                key={props.data.Id}
            >
                {(items && items.length > 2) ?
                    <Dropdown placement='bottom' menu={{ items }} key={'key'}>
                        <Button className="border-0 text-2xl bg-transparent flex items-center">
                            <EllipsisOutlined className="rotate-90 font-semibold" />
                        </Button>
                    </Dropdown> : items?.map((i: any) => (
                        i !== null && (
                            <Tooltip key={i.key} title={i.label}><Button disabled={i.disabled} size={"small"} onClick={i.onClick} icon={i.icon}></Button></Tooltip>

                        )
                    ))
                }
            </div>

        </div>

    );
}

export default ActionCell;
