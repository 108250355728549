import axios from "axios";
import { CommonListResponse } from "../@types/Response";
import { Trip, TripApproval, TripListSchema } from "../@types/Trip";
import envConfig from "../../enviroment/enviroment";
import { LeaveOptions } from "../@types/FetchOptions";

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const GET_TRIP_LIST = `${API_URL}/trips/list`
export const GET_TRIP = `${API_URL}/trips`
export const ADD_TRAVELER_TO_TRIP = `${API_URL}/trips/add-traveller`
export const REMOVE_TRAVELER_FROM_TRIP = `${API_URL}/trips/remove-traveller`
export const APPROVE_TRIP = `${API_URL}/trips/approve`
export const REJECT_TRIP = `${API_URL}/trips/reject`
export const GET_TRIP_APPROVALS = `${API_URL}/trips/my-approvals`
export const GET_SINGLE_TRIP_APPROVAL = `${API_URL}/trips/approval`
export const fetchTrips = async ({
    pageIndex = 0,
    pageSize = 10,
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    sorting = 'From:desc',
    personIds = '',
}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
    });
    const response = await axios.get<CommonListResponse<Trip>>(
        `${GET_TRIP_LIST}?${params.toString()}`,
    );
    return response.data;
}
export const getTripList = async ({
    pageIndex = 0,
    pageSize = 10,
    sorting = 'LatestArrivalTime:desc',
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    personIds = '',
    advancedFilter = ''
}: LeaveOptions = {}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
        advancedFilter
    });
    try {
        const response = await axios.get<CommonListResponse<Trip>>(
            `${GET_TRIP_LIST}?${params.toString()}`,
        );
        // await TripListSchema.validate(response.data.Results)

        return response.data;
    } catch (error) {
        console.error('Error fetching trips', error);
        throw error;
    }
}
export const fetchTripById = async (id: number) => {
    try {

        const response = await axios.get<Trip>(`${GET_TRIP}?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
};

export const fetchApprovalTripById = async (id: number) => {
    try {
        const response = await axios.get<TripApproval>(`${GET_SINGLE_TRIP_APPROVAL}?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching single trip approval', error);
        throw error;
    }
}
export const deleteTripById = async (id: number) => {
    try {
        const response = await axios.post<Trip>(`${GET_TRIP}/cancel`, { tripId: id });
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}

export const saveTrip = async (data: Trip) => {
    try {
        const response = await axios.post<''>(`${GET_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}


export const submitTrip = async (id: number) => {
    try {
        const response = await axios.post<''>(`${GET_TRIP}/submit`, id);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}

export const approveTrip = async (data: { id: number, comments: string }) => {
    try {
        const response = await axios.post<''>(APPROVE_TRIP, data);
        return response.data
    } catch (error) {
        console.error('Error approving trip:', error)
        throw (error);
    }
}
export const rejectTrip = async (data: { id: number, comments: string }) => {
    try {
        const response = await axios.post<''>(REJECT_TRIP, data);
        return response.data
    } catch (error) {
        console.error('Error rejecting trip:', error);
        throw error;
    }
}

export const fetchApprovalTrips = async ({
    pageIndex = 0,
    pageSize = 10,
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    personIds = '',
}: LeaveOptions = {}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        status,
        from,
        to,
        personIds,
    });
    try {
        const response = await axios.get<CommonListResponse<TripApproval>>(
            `${GET_TRIP_APPROVALS}?${params}`,
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const addTraveler = async (data: { employeeId: number, tripId: number }) => {
    try {
        const response = await axios.post<''>(`${ADD_TRAVELER_TO_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error adding traveler to trip', error)
        throw error;
    }
}


export const removeTraveler = async (data: { employeeId: number | undefined, tripId: number | undefined }) => {
    try {
        const response = await axios.post<''>(`${REMOVE_TRAVELER_FROM_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error adding traveler to trip', error)
        throw error;
    }
}