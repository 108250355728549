import "./Sentry";
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './app/routing/AppRoutes';
import { AuthProvider, useAuth, setupAxios } from './app/features/auth';
import './styles.css';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (container) {
  const routes: RouteObject[] = [
    { path: '/*', element: <AppRoutes /> }
  ];
  const router = createBrowserRouter(routes);

  // Create a wrapper component to access AuthContext
  const AxiosSetup = () => {
    const { setAuth } = useAuth(); // Get auth state from context

    // Pass auth state to setupAxios
    setupAxios(axios, router.navigate, setAuth);

    return null;
  };

  if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
  }

  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AxiosSetup />
        <RouterProvider router={router} />
      </AuthProvider>
    </QueryClientProvider>,
  );
}
