import { FC, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import Chart from 'react-apexcharts';
import useChartHeight from './useChartHeight';
import Loader from '../Loader';
import { Switch } from 'antd';
import { Timesheet, TimesheetEntry } from '../../@types/Timesheet';

type Props = {
  className: string;
  data: Timesheet[];
  loading: boolean;
};

const ChartsWidget9: FC<Props> = ({ className, data = [], loading }) => {
  const { chartRef, height } = useChartHeight();

  const [isProjectOnly, setIsProjectOnly] = useState(true);
  const formatedData = genHeatmapOptions(data, isProjectOnly);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5 p-4 flex flex-row justify-between items-center">
        <h3 className="card-title flex align-items-start flex-col">
          <span className="card-label fw-bold fs-3 mb-1">Last 12 Months</span>
          <span className="text-gray-400/[.5]  text-sm">
            {isProjectOnly ? 'Project' : 'Work package'} hours / Month
          </span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar" data-kt-buttons="true">
          <div className="form-check flex flex-row items-center form-switch form-check-custom form-check-solid gap-3">
            <label
              className="form-check-label"
            >
              Projects only
            </label>
            <Switch
              className='w-2'
              checked={isProjectOnly}
              onChange={() => setIsProjectOnly(!isProjectOnly)}
            />

          </div>

        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body text-center">
        {/* begin::Chart */}
        {loading ? (
          <Loader />
        ) : (
          <div
            ref={chartRef}
            style={{ height }}
            id="kt_charts_widget_9_chart"
            className="card-rounded-bottom"
          >
            <Chart
              height={height}
              options={formatedData}
              series={formatedData?.series ?? []}
              type="heatmap"
            />
          </div>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default ChartsWidget9;

type ProjectWP = TimesheetEntry & {
  name: string;
};

interface MonthData {
  name: string;
  data: number[];
}

function genHeatmapOptions(
  data: Timesheet[],
  isProjectOnly: boolean,
): ApexOptions {
  const labelColor = '#adb5bd';
  const strokeColor = '#e9ecef';

  const primaryColor = '#0d6efd';

  const monthList: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ].reverse();

  const prevMonth = moment().subtract(1, 'months').format('MMM');
  const splitMonth = monthList.indexOf(prevMonth);
  const beforeCurrentMonth = monthList.slice(0, splitMonth);
  const afterCurrentMonth = monthList.slice(splitMonth);

  const rearrangedSeries = [...afterCurrentMonth, ...beforeCurrentMonth];

  const series: ApexAxisChartSeries = rearrangedSeries.reduce(
    (prev: ApexAxisChartSeries, curr: MonthData['name']) => [
      ...prev,
      {
        name: curr,
        data: formatData(curr, data, isProjectOnly),
      },
    ],
    [],
  );

  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 1,
      colors: [strokeColor],
    },
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      categories: extractProjectWP(data, isProjectOnly).map((x) => x.name),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' hours';
        },
      },
    },
    colors: [primaryColor],
    grid: {
      borderColor: strokeColor,
      padding: {
        top: 0,
        bottom: 0,
        left: 8,
        right: 0,
      },
    },
  };

  function formatData(
    month: MonthData['name'],
    data: Timesheet[],
    isProjectOnly: boolean,
  ): number[] {
    return extractProjectWP(data, isProjectOnly).map((projectWP) => {
      const totalHours = data.reduce((prev, timesheet) => {
        if (moment(timesheet.Month).format('MMM') === month) {
          const hoursWorked = timesheet.TimesheetEntries.reduce(
            (acc, entry) => {
              if (
                isProjectOnly
                  ? entry.ProjectAcronym === projectWP.ProjectAcronym
                  : entry.ProjectAcronym === projectWP.ProjectAcronym &&
                  entry.WPId === projectWP.WPId
              ) {
                return acc + entry.HoursWorked;
              } else {
                return acc;
              }
            },
            0,
          );

          return prev + hoursWorked;
        } else {
          return prev;
        }
      }, 0);

      return totalHours;
    });
  }

  function extractProjectWP(
    data: Timesheet[],
    isProjectOnly: boolean,
  ): ProjectWP[] {
    const projectWPSet = new Set<string>();
    const uniqueProjectWPs: ProjectWP[] = [];

    data.forEach((timesheet) => {
      timesheet.TimesheetEntries.forEach(({ ...entry }) => {
        const projectName = isProjectOnly
          ? entry.ProjectAcronym
          : `${entry.ProjectAcronym} - ${entry.WPNumber}`;

        if (!projectWPSet.has(projectName)) {
          projectWPSet.add(projectName);
          uniqueProjectWPs.push({
            ...entry,
            name: projectName,
          } as unknown as ProjectWP);
        }
      });
    });
    return uniqueProjectWPs.sort((a, b) => a.name.localeCompare(b.name));
  }
}
