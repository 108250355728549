import { Button, DatePicker, Dropdown, Grid, Select, Space, Switch, TableColumnType } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { ReactElement, useEffect, useState } from 'react'
import moment from 'moment';
import { SettingOutlined, RedoOutlined } from '@ant-design/icons';
import useTableContext from '../../features/table/provider/TableContext';
import { DebouncedInput } from '../../components/DebouncedInput/DebouncedInput';
import LoomButton from '../../components/ui/LoomButton';
import { fetchOrganisation } from '../../api-requests/Organisation';
import { ExtendedTableColumnType } from '../../features/timesheet/components/TimesheetTable';
import { MeModeButton } from '../../components/employeeSidebar/MeModeButton';
import { AssigneesButton } from '../../components/employeeSidebar/AssigneesButton';
import TableCustomisation from './TableCustomisation';
import { FetchOrganisationType } from '../../@types/Organisation';
import { TeamProvider } from '../../features/humanResources/teams/provider/TeamProvider';



const { useBreakpoint } = Grid;

export type ExtendedColumnType<T> = {
    key: string;
    title: string;
    hidden: boolean;
    dataIndex: string;
    defaultSortOrder?: 'ascend' | 'descend';
} & TableColumnType<T>;


type Props<T> = {
    extendedCalendar?: boolean;
    Export?: ReactElement;
    hasAdd?: boolean;
    setIsCreateModal?: (b: boolean) => void;
    meModeFilter?: boolean;
    addNavigate: (() => void);
    columns: ExtendedTableColumnType<T>[];
    setNewColumns: React.Dispatch<React.SetStateAction<TableColumnType<T>[]>>;
    newColumns: TableColumnType<T>[];
    localStorageKey: string;
    hasStatus?: boolean;
    hasFilters?: boolean;
    hasOrg?: boolean;
    assigneesFilter?: boolean;
    dropdown?: React.ReactElement;
    extraFilters?: React.ReactElement;
}


const { RangePicker } = DatePicker;
const DefaultOrg: FetchOrganisationType = {
    Id: 0,
    BusinessName: 'All',
    ShortName: 'All',
    LegalName: 'All',
};
const TableFilters = <T,>({ Export, dropdown, assigneesFilter = false, hasOrg = false, hasFilters = true, meModeFilter = true, hasAdd = true, addNavigate, columns, newColumns, setNewColumns, localStorageKey, hasStatus = false, extraFilters }: Props<T>) => {
    const { md } = useBreakpoint();
    const {
        handleSearch,
        handleCheckboxChange,
        filterStates,
        searchTerm,
        handleRangeDate,
        handleOrgFilter,
        orgId,
        handleReset,
    } = useTableContext();
    const [openCustomisation, setOpenCustomisation] = useState<boolean>(false)
    const [organisations, setOrganisations] = useState<FetchOrganisationType[]>(
        [],
    );

    useEffect(() => {
        if (hasOrg) {
            fetchOrganisation()
                .then((res) => setOrganisations([DefaultOrg, ...res]))
                .catch((err) => console.log(err));
        }
    }, []);


    useEffect(() => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(newColumns));
    }, [newColumns]);


    return (
        <TeamProvider >
            <div className="flex w-full justify-between mb-6">
                <TableCustomisation setOpenCustomisation={setOpenCustomisation} openCustomisation={openCustomisation} columns={columns} localStorageKey={localStorageKey} setNewColumns={setNewColumns} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div className="example-header pe-3 flex flex-row gap-2">
                        <DebouncedInput
                            onChange={handleSearch}
                            initValue={searchTerm}
                            className="form-control form-control-solid w-250px ps-14 mb-2"
                            placeholder="Search..."
                            type="search"
                        />
                        {hasOrg && (
                            <div data-testid="selectOrg" className='md:min-w-[200px] min-w-[100px]'>
                                <Select<number>
                                    style={{ width: '100%' }}
                                    placeholder="Organisation"
                                    value={organisations.filter((org) => org.Id === orgId)[0]?.Id}
                                    onChange={(newValue) => {
                                        handleOrgFilter(newValue);
                                    }}
                                    options={organisations.map((d) => {
                                        return {
                                            label: d.BusinessName,
                                            value: d.Id,
                                        };
                                    })}
                                />
                            </div>
                        )}

                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Space>
                        {dropdown && md && dropdown}
                        {meModeFilter && <MeModeButton />}
                        {assigneesFilter && <AssigneesButton title='People' />}
                        {extraFilters && extraFilters}
                        <Button icon={<SettingOutlined />} className="md:rounded-xl " size={"small"} onClick={() => { setOpenCustomisation(!openCustomisation) }}>{md && 'Customise'}</Button>
                        {hasFilters && (<Dropdown
                            placement="topLeft"
                            dropdownRender={() => (
                                <div
                                    style={{
                                        padding: 10,
                                        background: 'white',
                                        border: '1px solid #ede8e8',
                                        borderRadius: '0.75rem',
                                        boxShadow: '1px 1px 8px 2px #f5f4f4',
                                    }}
                                >
                                    <div
                                        className="flex flex-col"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {hasStatus && <div className="lg:px-5 px-5 lg:w-[400px] w-[200px]" >
                                            <h4 className="text-center mb-2">Filter by status</h4>
                                            <div className='grid grid-cols-12 mb-0'>
                                                {filterStates.map((checkbox, index: number) => (
                                                    <div className="lg:col-span-6 col-span-12 my-2 mb-2" key={index}>
                                                        <div className="flex lg:gap-2 gap-4" key={checkbox.label}>
                                                            <Switch
                                                                id={checkbox.label}
                                                                checked={checkbox.value}
                                                                onChange={() => {
                                                                    handleCheckboxChange(checkbox.Id);
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={checkbox.label}
                                                            >
                                                                {checkbox.label}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>}
                                        <div
                                            className="fv-row"
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            {/* begin::Form group Lastname */}
                                            <h4 className="text-center">Date range</h4>
                                            <RangePicker
                                                onChange={(date) => {
                                                    if (date !== null) {
                                                        handleRangeDate([
                                                            moment(date[0]?.toDate()).format(
                                                                'YYYY-MM-DD',
                                                            ),
                                                            moment(date[1]?.toDate()).format(
                                                                'YYYY-MM-DD',
                                                            )]
                                                        );
                                                    }
                                                    else {
                                                        handleRangeDate([]);
                                                    }
                                                }}
                                                size={'large'}
                                                className=" cursor-pointer"
                                                format="DD-MM-YYYY"
                                                style={{
                                                    display: 'flex',
                                                }}
                                            />
                                        </div>
                                        {/* <div className='p-5'>
                                        <h4 className="text-center">Columns</h4>
                                        <Checkbox.Group
                                            value={checkedList}
                                            onChange={(value) => {
                                                console.log(value);
                                                setCheckedList(value as string[]);
                                            }}
                                            className='flex justify-center'
                                        >
                                            <Row>
                                                {options.map((opt:any, i:number) => (
                                                    <Col span={6} key={i}>
                                                        <Checkbox value={opt.value}>{opt?.label}</Checkbox>
                                                    </Col>
                                                ))}

                                            </Row>
                                        </Checkbox.Group>
                                    </div> */}
                                    </div>
                                </div>
                            )}

                        >
                            <Button
                                icon={<FilterOutlined />}
                                size="small"
                                className='md:rounded-xl'
                            >{md && 'Filters'}</Button>
                        </Dropdown>)}
                        <Button
                            icon={<RedoOutlined />}
                            size="small"
                            onClick={() => handleReset()}
                        />
                        {Export}
                        {hasAdd && <LoomButton icon={'add'} click={() => addNavigate()} />}
                    </Space>
                </div>
            </div>
        </TeamProvider>
    )
}

export default TableFilters