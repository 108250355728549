import axios from "axios";
import envConfig from "../../enviroment/enviroment";

const API_URL = envConfig.getEnvKey('APP_DEV_URL');

export const LOGIN = `${API_URL}/oauth/signin`;

export const login = async (values: { username: string, password: string }) => {
    try {
        const response = await axios.post<{ Token: string }>(LOGIN, values);
        return response.data;
    } catch (error) {
        console.error('Error creating team:', error);
        throw error;
    }
}